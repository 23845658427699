import React, { forwardRef, useState, type HTMLAttributes } from 'react';
import { Swiper, SwiperSlide, type SwiperClass, type SwiperProps } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { type Locale } from '@i18next/client/utils';
import type { CaseStudyEntry } from '@type/caseStudies/default';
import Card from '@react/widget/Card';
import { H2 } from '@react/widget/Headline';
import Quote from '@react/widget/Quote';
import Author from '@react/widget/Author';
import Img from '@react/widget/Img';
import Button from '@react/widget/Button';
import Icon from '@react/widget/Icon';
import clsx from 'clsx';
import type { SwiperModule } from 'swiper/types';

interface NavButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

const NavButton = forwardRef<HTMLButtonElement, NavButtonProps>(({ children, className, ...props }, ref) => (
  <button
    ref={ref}
    className={`${className} absolute top-[calc(50%-40px)] z-[1] flex items-center justify-center rounded-full w-[35px] h-[35px] lg:w-[50px] lg:h-[50px] text-center text-primary transition-all duration-300 ease-in-out xl:hover:shadow-md xl:hover:text-white bg-primary xl:bg-white`}
    {...props}
  >
    <span className="inline-block">{children}</span>
  </button>
)
);

interface Props extends SwiperProps {
  locale: Locale;
  data: Array<CaseStudyEntry>;
  label: {
    readCustomersStory: string; // "Read Customer's Story"
  }
}

const CaseStudies: React.FC<Props> = ({ locale, className, autoHeight = true, spaceBetween = 10, loop = true, data, label, ...props }) => {
  if(!data?.length)
    return null;

  const [swiper, setSwiper] = useState<SwiperClass>();

  const modules: Array<SwiperModule> = [];
  if (!!props.pagination)
    modules.push(Pagination);
  if (!!props.autoplay) {
    props.autoplay = Object.assign({
      delay: 3000,
      disableOnInteraction:	true,
      pauseOnMouseEnter: true,
      reverseDirection: false,
      stopOnLastSlide: false,
      waitForTransition: true,
    }, (typeof props.autoplay === "object") ? props.autoplay: {});
    modules.push(Autoplay);
  }
  if (!!props.navigation)
    props.navigation = false;
    // modules.push(Navigation);

  return (
    <div className={clsx("relative", className)}>
      <Swiper
        autoHeight={autoHeight}
        spaceBetween={spaceBetween}
        loop={loop}
        onSwiper={(swiper) => setSwiper(swiper)}
        modules={modules}
        {...props}
      >
        {data?.map((_cs, i) => {
          const { url, number, headline, subtitle, preview: [preview] = [], customer: [customer] = [], quote: [quote] = [] } = _cs;
          return <SwiperSlide key={`carousel-${i}`}>
            <Card bgColor='white' space="p-8 md:p-16">
              <div className="flex border-b pb-8 max-lg:flex-col-reverse">
                <div className="max-w-3xl mx-auto">
                  <H2 className="pb-8">{preview?.title}</H2>
                  <Quote size="text-xl">{quote?.context}</Quote>
                  <Author>
                    <div slot="infro">
                      <span className="name">{quote?.source}</span>
                      <p className="position">{quote?.titlePosition}</p>
                    </div>
                  </Author>
                </div>
                <div className="lg:pl-12 mx-auto mb-6">
                  <div className="rounded-lg bg-layout p-6 sm:w-80 lg:mx-auto text-center">
                    <div><Img className="w-fit mx-auto inline-block max-w-[180px]" {...(customer?.caseStudyLogos[0] ?? {})} format='webp' /></div>
                    {(!!headline || !!subtitle) && <div className="border-t pt-4 mt-8">
                      <span className="text-66 font-semibold">{!!number && parseFloat(number).toLocaleString("en-US")}</span><span className="inline-block pl-1 text-3xl lg:text-4xl">{headline}</span>
                      <p className="mt-2">{subtitle}</p>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="pt-8">
                <Button style="primary" href={url}>{label.readCustomersStory}</Button>
              </div>
            </Card>
          </SwiperSlide>
        })}
      </Swiper>
      <div className="controlsContainer absolute top-1/2 inset-x-0">
        <NavButton className="left-0 left-[-15px] lg:left-[-88px]" onClick={() => swiper?.slidePrev()}>
          <Icon className="w-5 h-5 text-white xl:text-black" name="chevron-left" />
        </NavButton>
        <NavButton className="right-0 right-[-15px] lg:right-[-88px]" onClick={() => swiper?.slideNext()}>
          <Icon className="w-5 h-5 text-white xl:text-black" name="chevron-right" />
        </NavButton>
      </div>
    </div>
  );
};

export default CaseStudies;